import React, { Component } from 'react'
import { connect } from 'react-redux'

class ChatInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    }

    this.onChangeText = this.onChangeText.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChangeText(event) {
    this.setState({ text: event.target.value })
  }

  onSubmit(event) {
    event.preventDefault()
    this.props.dispatch(
      this.props.submitAction(this.state.text)
    )
    this.setState({ text: '' })
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <input
          value={this.state.text}
          onChange={this.onChangeText}
          placeholder={this.props.placeholder}
        />
      </form>
    )
  }
}

export default connect()(ChatInput)
