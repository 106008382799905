import * as firebase from 'firebase'
import { combineReducers } from 'redux'


const status = (state = null, action) => {
  switch (action.type) {
    case 'CHAT_OPENED':
      return Object.assign({}, state, { isLive: true })
    default:
      return state
  }
}

const message = (state = null, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return {
        id: action.id,
        message: action.text,
        time: action.time,
        user: action.user
      }
    case 'SEND_MESSAGE':
      let message = {
        message: action.text,
        time: Date.now(),
        user: action.user
      }

      const newMessageRef = firebase.database().ref('messages').push()
      message.id = newMessageRef.key
      return message
    default:
      return state
  }
}

const messages = (state = [], action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      if (state.map(message => message.id).includes(action.id)) {
        return state
      } else {
        return [ message(undefined, action), ...state ]
      }
    case 'SEND_MESSAGE':
      return [ message(undefined, action), ...state ]
    default:
      return state
  }
}


const chat = combineReducers({
  messages,
  status
})

export default chat