import React, { Component } from 'react'
import { connect } from 'react-redux'

import Messages from '../Messages'
import ChatInput from '../ChatInput'
import { sendMessage, fetchMessages } from '../../chat/actions'
import { signOut } from '../../user/actions'

class Chat extends Component {
  constructor(props) {
    super(props)
    this.sendMessage = this.sendMessage.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  componentWillMount() {
    this.props.dispatch(fetchMessages())
  }

  signOut() {
    this.props.dispatch(signOut())
  }

  sendMessage(text) {
    return sendMessage(text, this.props.user)
  }

  render() {
    return (
      <div>
        <h1>Chatterbox - Awesome Chat</h1>
        <button onClick={this.signOut}>Log out</button>
        <ChatInput submitAction={this.sendMessage} placeholder="Type here..."/>
        <Messages />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(Chat)