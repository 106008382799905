import firebase from 'firebase/app'

const config = {
  apiKey: "AIzaSyBxrwlBPEEQHvPuAvD6YvIT1kJ4fUfmVjE",
  authDomain: "chatterbox-afc4d.firebaseapp.com",
  databaseURL: "https://chatterbox-afc4d.firebaseio.com",
  projectId: "chatterbox-afc4d",
  storageBucket: "chatterbox-afc4d.appspot.com",
  messagingSenderId: "664766741395"
}
try {
  firebase.initializeApp(config)
} catch (err) {
// we skip the "already exists" message which is
// not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)
  }
}

export default firebase