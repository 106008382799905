import firebase from '../firebase'

export const username = (name) => ({
  type: 'USER_NAME',
  name
})

export const signOut = () => {
  return dispatch => {
    firebase.auth().signOut().catch(console.log)
  }
}