import * as es6 from 'es6-shim'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import firebase from '../firebase'

import Chat from '../components/Chat'
import Login from '../components/Login'

import { username } from '../user/actions'
import rootReducer from '../rootReducer'

const loggerMiddleware = createLogger()

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunkMiddleware,
      loggerMiddleware
    ))
)

class LoginOrChat extends Component {
  componentWillMount() {
    firebase.auth().onAuthStateChanged(user => {
      const name = user && user.displayName
      this.props.dispatch(username(name))
    });
  }

  render() {
    return this.props.user && this.props.user.name ? <Chat /> : <Login />
  }
}

const mapStateToProps = (state) => ({
  user: state.user
})

const LoginOrChatContainer = connect(mapStateToProps)(LoginOrChat)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <LoginOrChatContainer />
      </Provider>
    )
  }
}

export default App