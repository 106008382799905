import React, { Component } from 'react'
import firebase from '../../firebase'

import ChatInput from '../ChatInput'
import { username } from '../../user/actions'

class Login extends Component {
  constructor(props) {
    super(props)

    this.googleLogin = this.googleLogin.bind(this)
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')
    provider.addScope('https://www.googleapis.com/auth/plus.me')
    firebase.auth().signInWithPopup(provider).catch(console.log)
  }

  render() {
    return (<div>
        <h2>Login</h2>

        <div><button onClick={this.googleLogin}>Login with Google</button></div>
        <label>User:</label>
        <ChatInput
          placeholder="Username..."
          submitAction={username} />
      </div>
    )
  }
}

export default Login