import React from 'react'
import { connect } from 'react-redux'

const Messages = ({ messages }) => {
  return (
    <div>
      {messages && messages.length > 0 ?
        messages.map(message => (
          <div key={message.id}>
            <hr />
            <div className="user">{message.user} - { new Date(message.time).toDateString() }</div>
            <div className="message">{message.message}</div>
          </div>
        )) :
        'No messages' }
    </div>
  )
}

const mapStateToProps = (state) => ({
  messages: state.chat.messages
})

export default connect(mapStateToProps)(Messages)