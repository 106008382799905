import React from 'react'
import { render } from 'react-dom'
import App from './App'

render(
  <App />,
  document.getElementById('app')
)

// import xs from 'xstream';
// import {makeFirebaseDriver} from 'cycle-firebase'
// import Firebase from 'firebase'
// import { run } from '@cycle/run';
// import { div, button, p, makeDOMDriver } from '@cycle/dom';
//
// let main = ({DOM, firebase}) => {
//   // let messageQuery = firebase.child('messages').ref()
//   // let messages$ = firebase.value(messageQuery).map(message => console.log(`message: ${message}`))
//
//   const action$ = xs.merge(
//     DOM.select('.decrement').events('click').map(ev => -1),
//     DOM.select('.increment').events('click').map(ev => +1)
//   );
//
//   const count$ = action$.fold((acc, x) => acc + x, 0);
//
//   const vdom$ = count$.map(count =>
//     div([
//       button('.decrement', 'Decrement'),
//       button('.increment', 'Increment'),
//       p('Counter: ' + count)
//     ])
//   );
//
//   return {
//     DOM: vdom$
//   };
// }
//
// const fb = makeFirebaseDriver(new Firebase("https://chatterbox-afc4d.firebaseio.com/"))
// run(main, {
//   firebase: fb,
//   DOM: makeDOMDriver('#main')
// });
//
