import firebase from '../firebase'

export const addMessage = (message) => {
  return Object.assign({}, message, { type: 'ADD_MESSAGE' })
};

export const sendMessage = (text, user) => {
  console.log('text: ', text)
  console.log('user: ', user)
  return dispatch => {
    let message = {
      text: text,
      time: Date.now(),
      user: user.name
    };

    const newMsgRef = firebase.database()
      .ref('messages')
      .push();
    message.id = newMsgRef.key;
    newMsgRef.set(message);

    dispatch(addMessage(message));
  };
};

export const fetchMessages = () => {
  return dispatch => {
    // dispatch(chatOpened())

    firebase.database()
      .ref('messages')
      .orderByKey()
      .limitToLast(20)
      .on('value', snapshot => {
        setTimeout(() => {
          const messages = snapshot.val() || []
          dispatch(receiveMessages(messages))
        }, 0)
      })
  }
}

export const receiveMessages = (messages) => {
  return dispatch => {
    Object.values(messages).forEach(message => dispatch(addMessage(message)))
    // dispatch(receivedMessages())
  }
}